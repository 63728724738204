<template>
  <div class="mt-16 sm:mt-20">
    <div
      class="-my-4 flex justify-center gap-5 py-4 sm:gap-8 animate-loop-scroll group-hover:paused"
    >
      <div
        class="relative aspect-9/10 w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 sm:w-72 sm:rounded-2xl"
        v-for="(src, key) in images"
        :key="'galery-image-' + key"
        :class="{
          'rotate-2': key % 2 !== 0,
          '-rotate-2': key % 2 === 0
        }"
      >
        <img
          alt=""
          rel="preload"
          loading="lazy"
          decoding="async"
          data-nimg="1"
          fetchpriority="high"
          class="absolute inset-0 h-full w-full object-cover"
          style="color: transparent"
          sizes="(min-width: 640px) 18rem, 11rem"
          :src="src + '?' + key"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const images = [
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330',
  'https://picsum.photos/300/330'
]
</script>

<style scoped>
.paused {
  animation-play-state: paused;
}
</style>
