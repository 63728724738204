<template>
  <nav class="pointer-events-auto inline-block">
    <ul
      class="inline-flex rounded-full px-3 text-sm font-medium bg-white/90 text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur-sm dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10"
    >
      <li
        :key="'nav-component-' + k"
        v-for="(link, k) in [
          {
            id: 'home',
            text: t('nav.home'),
            href: '/'
          },
          {
            id: 'experiences',
            text: t('nav.experiences'),
            href: '/experiences'
          },
          {
            id: 'knowledge',
            text: t('nav.knowledge'),
            href: '/knowledge'
          },
          {
            id: 'posts',
            text: t('nav.posts'),
            href: '/posts'
          }
        ]"
        :id="'nav-router-link-' + link.id"
      >
        <RouterLink class="relative block px-2 md:px-3 py-2 hover:text-teal-500" :to="link.href">
          {{ link.text }}
          <span></span>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
